@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.swiper-pagination-bullet {
  @apply bg-primaryGON-200;
}

.swiper-pagination-bullet-active {
  @apply bg-primaryGON-500;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  display: none;
}
